import React from 'react'
import { graphql } from 'gatsby'
import ProductGrid from '~/components/ProductGrid'
import Container from '../components/ui/Container'
import SEO from '../components/seo'

const IndexPage = ({ data }) => {
  const products = data.allShopifyProduct.edges.map(item => item.node)
  console.log(products)
  return (
    <>
      <SEO title="Read to ship" />
      <Container>
        <ProductGrid
          heading="Ready to ship"
          description="The majority of my work is made-to-order, but on occasion I make things for general sale. when I do, they'll be listed here — grab 'em while they're hot!"
          products={products}
          isBespoke
        />
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    allShopifyProduct(filter: { availableForSale: { eq: true } }) {
      edges {
        node {
          availableForSale
          id
          handle
          title
          productType
          variants {
            price
          }
          images {
            id
            originalSrc
            localFile {
              childImageSharp {
                fluid(maxWidth: 910, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
