import React from 'react'
import { Flex, Box } from 'reflexbox'

import Heading from './ui/Heading'
import Section from './ui/Section'
import Product from './ui/Product'
import Text from './ui/core/Text'
import Button from './ui/Button'

const ProductGrid = ({
  products,
  heading,
  description,
  handle,
  isBespoke = false,
}) => {
  return (
    <Section isTop>
      <Heading level={2} as="h2" mb={3}>
        {isBespoke ? heading : `The ${heading}`}
      </Heading>
      {description && (
        <Box maxWidth="800px" mb={4}>
          <Text fontSize={[3, 4]}>{description}</Text>
        </Box>
      )}
      <Flex mx={[-2, -3]} flexWrap="wrap">
        {products ? (
          products.map(product => (
            <Box
              px={[2, 3]}
              mb={3}
              width={[1 / 2, 1 / 2, 1 / 3]}
              key={product.id}
            >
              <Product
                {...product}
                displayPrice={`$${product.variants[0].price}`}
              />
            </Box>
          ))
        ) : (
          <p>No Products found!</p>
        )}
      </Flex>
      {handle && (
        <Flex
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent="space-between"
        >
          {!isBespoke ? (
            <Box fontSize={[3, 4]} maxWidth="800px" mb={4}>
              Don't see exactly what you're looking for? Prefer a different
              color, leather, or an extra pocket? Don't sweat it! you can
              request a custom version of the {heading} made to your exact
              specification!
            </Box>
          ) : (
            <Box fontSize={[3, 4]} maxWidth="800px" mb={4}>
              Looking for a leather item that isn't here? Thats great news, I
              love working on new ideas! Reach out and tell me what you have in
              mind and lets create something together.
            </Box>
          )}
          <Box minWidth={330}>
            <Button
              mb={[3, 0, 0, 4]}
              mr={[0, 4, 4, 0]}
              width={['100%', '100%', '100%', 'auto']}
              minWidth={300}
              to={isBespoke ? '/custom' : `/product/custom-${handle}`}
            >
              {isBespoke ? 'Custom order' : `Order a custom ${heading}`}
            </Button>
          </Box>
        </Flex>
      )}
    </Section>
  )
}

export default ProductGrid
