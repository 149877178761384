import React from 'react'
import Heading from './Heading'
import ProductImage from './ProductImage'
import { Box } from 'reflexbox'
import Link from './core/Link'

const Product = ({ images, handle, title, displayPrice, availableForSale }) => (
  <Box as="article" mb={3}>
    <Link to={`/product/${handle}`}>
      <ProductImage
        sizes="335px, (max-width: 1024px) 460px, (max-width: 1440px) 940px"
        fluid={images[0].localFile.childImageSharp.fluid}
        alt="title"
      />
    </Link>
    <Box
      as="span"
      fontFamily="sans"
      fontSize={1}
      fontWeight="bold"
      color={availableForSale ? 'green' : 'red'}
    >
      {availableForSale ? (
        'Ready to ship'
      ) : (
        <Link to={`/product/${handle}`} as="span" color="blue">
          Commission your own
        </Link>
      )}
    </Box>
    <Heading level="4" as="h3">
      <Link to={`/product/${handle}`}>{title}</Link>
    </Heading>
    {availableForSale && <span>{displayPrice}</span>}
  </Box>
)

export default Product
